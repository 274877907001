import {Swiper, Pagination, Navigation, Grid, Autoplay, Thumbs} from "swiper";

new Swiper(".mainSwiper", {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    navigation: {
        nextEl: ".main-swiper-button-next",
        prevEl: ".main-swiper-button-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

new Swiper(".reviewsSwiper", {
    modules: [Pagination, Navigation, Grid],
    slidesPerView: 5,
    spaceBetween: 20,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".reviews-swiper-button-next",
        prevEl: ".reviews-swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        520: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1500: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    },
});

new Swiper(".certificateSwiper", {
    modules: [Pagination, Navigation, Grid],
    slidesPerView: 5,
    spaceBetween: 20,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".certificate-swiper-button-next",
        prevEl: ".certificate-swiper-button-prev",
    },
    breakpoints: {
        300: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        520: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1500: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    }
});

const swiper = new Swiper(".thumbsSwiper", {
    spaceBetween: 20,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    breakpoints: {
        320: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        576: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
    },
});

new Swiper(".itemSwiper", {
    modules: [Pagination, Navigation, Thumbs],
    spaceBetween: 10,
    loop: true,
    navigation: {
        nextEl: ".item-swiper-button-next",
        prevEl: ".item-swiper-button-prev",
    },
    thumbs: {
        swiper
    },
});
