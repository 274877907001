import $ from 'jquery';

$(function () {
    const $contactItems = $('.app-contacts-item');
    $contactItems.on('click', function (e) {
        e.preventDefault();
        $contactItems.removeClass('active');
        $(this).addClass('active');

        $('.app-contacts-content').addClass('d-none');
        $('.app-contacts-map').addClass('d-none');

        $('.app-contacts-content[data-address-id='+ $(this).data('address-id') +']').removeClass('d-none');
        $('.app-contacts-map[data-address-id='+ $(this).data('address-id') +']').removeClass('d-none');
    });
});