import './utils/scrolling';
import './utils/sliders';
import './utils/menu';
import './utils/callback';
import './utils/contacts';
import $ from 'jquery';
import Inputmask from "inputmask";
import { Modal } from 'bootstrap';

window.$ = $;
window.jQuery = $;
window.Modal = Modal;


require('@fancyapps/fancybox');

$(function () {
    const mask = new Inputmask('+7 (999) 999-99-99');
    mask.mask(document.querySelectorAll('.maskedinput-phone'));
});